import Swiper from 'swiper';
import { Pagination, Autoplay, Parallax, Navigation, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


const slidersInit = ()=> {
    const heroSliderInit = () => {

        const swiper = new Swiper('.hero__slider', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay, Parallax, Keyboard],
            pagination: {
                el: '.hero__slider-pagination',
                clickable: true,
            },
            simulateTouch: false,
            watchOverflow: true,
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            parallax: true,
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            // on: {
            //     slideChangeTransitionEnd: function (swiper) {
            //         console.log(swiper)
            //         console.log('slideChangeTransitionEnd')
            //     },
            // },
            speed: 500,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            },
        });
    }
    const productSliderInit = () => {

        const swiperProduct = new Swiper('.product__slider', {
            // configure Swiper to use modules
            modules: [Pagination, Keyboard],
            pagination: {
                el: '.product__slider-pagination',
                clickable: true,
            },
            // simulateTouch: false,
            watchOverflow: true,
            slidesPerView: 1,
            spaceBetween: 30,
            // loop: true,
            // parallax: true,
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            on: {
                slideChangeTransitionEnd: function (swiper) {
                    const videoElm = swiper.slides[swiper.activeIndex].querySelector('video');
                    if (videoElm) {
                        videoElm.play();
                    }
                },
                slideChangeTransitionStart: function (swiper) {
                    const videoElm = swiper.slides[swiper.previousIndex].querySelector('video');
                    if (videoElm) {
                        videoElm.pause();
                    }
                }
            },

        });

        return swiperProduct;
    }

    const productInteriorSliderInit = () => {

        const swiperInterior = new Swiper('.interiors__slider', {
            // configure Swiper to use modules
            modules: [Pagination],
            pagination: {
                el: '.interiors__slider-pagination',
                clickable: true,
            },
            simulateTouch: false,
            watchOverflow: true,
            slidesPerView: 1,
            spaceBetween: 0,
            // loop: true,
            // parallax: true,
            // centeredSlides: true,
            centerInsufficientSlides: true,
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            breakpoints: {
                // when window width is >= 320px
                576: {
                    slidesPerView: 2,
                },
                // when window width is >= 480px
                1024: {
                    slidesPerView: 3,
                },
                // when window width is >= 640px
                1320: {
                    slidesPerView: 4,
                }
            }
            // on: {
            //     slideChangeTransitionEnd: function (swiper) {
            //         console.log(swiper)
            //         console.log('slideChangeTransitionEnd')
            //     },
            // },

        });


        const interiorItems = document.querySelectorAll('.interior__item_slide');

        interiorItems.forEach((item) => {
            item.addEventListener('click', (e) => {
                const windowWidth = window.innerWidth;
                if (windowWidth < 1200) {
                    if (!e.target.closest('.interior__item_slide').classList.contains('click')) {
                        e.preventDefault();
                        e.target.closest('.interior__item_slide').classList.add('click');
                    }
                }
            });
        });
    }

    const productContentSliderInit = () => {

        const swiperProduct = new Swiper('.product__content_slider', {
            // configure Swiper to use modules
            modules: [Pagination],
            pagination: {
                el: '.product__content_slider-pagination',
                clickable: true,
            },
            simulateTouch: false,
            watchOverflow: true,
            slidesPerView: 1,
            spaceBetween: 20,
            // loop: true,
            // parallax: true,
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            breakpoints: {
                // when window width is >= 320px
                768: {
                    slidesPerView: 2,
                },
                // when window width is >= 480px
                1200: {
                    slidesPerView: 3,
                },

            }
            // on: {
            //     slideChangeTransitionEnd: function (swiper) {
            //         console.log(swiper)
            //         console.log('slideChangeTransitionEnd')
            //     },
            // },
        });

        // return swiperProduct;
    }

    const contentSliderInit = () => {

        const swiperContent = new Swiper('.content__slider', {
            // configure Swiper to use modules
            modules: [Pagination],
            pagination: {
                el: '.content__slider-pagination',
                clickable: true,
            },
            simulateTouch: false,
            watchOverflow: true,
            slidesPerView: 1,
            spaceBetween: 0,
        });

        return swiperContent;
    }



    const projectSliderInit = () => {

        const swiper = new Swiper('.project__slider', {
            // configure Swiper to use modules
            modules: [Pagination, Navigation, Keyboard],
            pagination: {
                el: '.project__slider-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.project__slider-button-next',
                prevEl: '.project__slider-button-prev',
            },
            // loop: true,
            // simulateTouch: false,
            autoHeight: true,
            watchOverflow: true,
            slidesPerView: 1,
            spaceBetween: 15,
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            // initialSlide: 0,
            // centerInsufficientSlides: true,
            // centeredSlides: true,
            // centeredSlidesBounds: true,
            grabCursor: true,
            breakpoints: {
                // when window width is >= 320px
                768: {
                    slidesPerView: "auto",
                },
                // when window width is >= 480px
                /*1200: {
                    slidesPerView: 3,
                },*/

            }

        });

    }


    const interiorSliderInit = () => {

        const swiperInteriror = new Swiper('.interior__hero_slider', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay, Keyboard],
            pagination: {
                el: '.interior__hero_slider-pagination',
                clickable: true,
            },
            simulateTouch: false,
            watchOverflow: true,
            slidesPerView: 1,
            spaceBetween: 0,
            loop: true,
            speed: 500,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            },
            keyboard: {
                enabled: true,
                onlyInViewport: true,
            },
            // on: {
            //     afterInit: function (swiper) {
            //         if (swiper.slides.length > 1) swiper.el.closest('.interior__hero_slider_wrapper').classList.add('has-pagination');
            //     },
            // }
        });

        return swiperInteriror;
    }





    return {
        heroSliderInit,

        productSliderInit,
        productInteriorSliderInit,

        productContentSliderInit,
        contentSliderInit,

        projectSliderInit,

        interiorSliderInit,
    }
}

export default slidersInit;
